import React, { useState, useContext, useEffect, useRef, Ref } from 'react';
import PropTypes from 'prop-types';
import { useAuth, IAuth } from '../auth/index';
import RequestService from '../../../services/RequestService';
import { useContracts } from 'really-easy-web3';
import { prettyAddress } from 'really-easy-web3';
import { useAccounts } from '../account/AccountsProvider';
import { Deserializer } from 'jsonapi-serializer';
export const ProfileContext = React.createContext({} as IProfileContext);
export const useProfile = () => useContext(ProfileContext);
import { useFlowWallet, IFlowState } from '../flow/FlowProvider';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';

interface IAccountAddress {
  id: number;
  address: string;
}

interface IProfile {
  id: number,
  userId: number,
  username: String,
  email: String,
  discordUsername: String,
  twitterUsername: String,
  facebookUsername: String,
  instagramUsername: String,
  avatarUrl: String,
  metamaskAddress: String,
  flowAddress: String,
  phantomAddress: String,
  accountAddresses: IAccountAddress[],
}
interface IFavorite {
  id: number,
  pirateId: number,
  pirateTokenId: number,
}

export interface IProfileContext {
  profile: IProfile,
  // accounts: any,
  reFetch: Function,
  reFetchFavorite: Function,
  refreshHolderPirates: Function,
  favorites: IFavorite[],
  holderPiratesIds: number[],
  primaryHolderPiratesIds: number[],
  holderFlowPiratesIds: number[],
  flowState: IFlowState,
  ethereumAccount: any,
  loadingProfile: Boolean,
  disableEthPropagation: Ref<Boolean>,
}



const ProfileProvider = ({ children }) => {
  const authState: IAuth = useAuth();
  const disableEthPropagation = useRef<Boolean>(false);
  const [loadingProfile, setLoading] = useState<Boolean>(true)
  const [profile, setProfile] = useState<IProfile>()
  const [favorites, setFavorites] = useState<[IFavorite] | []>([])
  const [holderPiratesIds, setHolderPiratesIds] = useState<number[] | []>([])
  const [primaryHolderPiratesIds, setPrimaryHolderPiratesIds] = useState<number[] | []>([])
  const [holderFlowPiratesIds, setHolderFlowPiratesIds] = useState<number[] | []>([])
  const ethereumAccount = useAccounts();
  
  const contracts = useContracts();
  const flowState = useFlowWallet();
  const router = useRouter();

  const fetchFavorites = async () => {
    const [response, _, error] = await RequestService.get('/favorites');
    
    if(!error){
      const favorites:[IFavorite] = await new Deserializer({
        id: "id",
        keyForAttribute: "camelCase",
        typeAsAttribute: false,
      }).deserialize(response);
  
      setFavorites(favorites || []);
    }
  };

  const fetchHolderTokenIds = async (profile: IProfile | null = null) => {
    const contract = contracts.getContract("Pirate NFTs");
    const { accountAddresses } = profile || { accountAddresses: [{address: ethereumAccount.default}]};
    const { default: defaultAddress } = ethereumAccount;

    if(contract && accountAddresses.length)
    {
      let tokenIds: number[] = [];
      for (const accountAddress of accountAddresses) {
        const list = await contract.tokensOfOwnerOffChain(accountAddress.address);
        const numberList = list.map(tokenId => tokenId.toNumber())
        if(accountAddress.address.toLowerCase() === defaultAddress.toLowerCase())
          setPrimaryHolderPiratesIds(numberList);

        tokenIds = [...tokenIds, ...numberList];
      }

      await setHolderPiratesIds(tokenIds);
    }
  }
  // }, [contracts, ethereumAccount])

  const fetchFlowHolderTokenIds = async (profile: IProfile | null = null) => {

    const { flowAddress } = profile || { flowAddress: flowState.user.addr };    
    if(flowAddress && global.claimEnabled)
    {
      const tokenIds = await flowState.tokensOfOwner(flowAddress);
      await setHolderFlowPiratesIds(tokenIds);
    }
  }
  // }, [flowState])

  const fetchAccount = async (includeHolderPirates = true, includeFavorites = true, setLoadingState = true) => {
    
    /* 
     * Dev Note
     * This provider is used to show a loading screen when it's loading
     * On refetch we don't want to unmount the current page
     **/
    if(setLoadingState) setLoading(true);

    const [response, _, error] = await RequestService.get(
      `/accounts/${ethereumAccount.default}`.toLowerCase(),
    );

    if (!error) {
      const profileData = await new Deserializer({
        id: "id",
        keyForAttribute: "camelCase",
        typeAsAttribute: false,
      }).deserialize(response);

      // WHen user connect metamask, he already has a flow wallet connected
      if(flowState.user && flowState.user.addr){

        // We need to check if the address added into account in the same
        // if not, we should disconnect the flow wallet
        if(!profileData.flowAddress || profileData.flowAddress.toLowerCase() !== flowState.user.addr.toLowerCase()){
          await flowState.disconnect();
        }
      }

      const showedUserName = profileData.username || prettyAddress(profileData.metamaskAddress)
      await setProfile({ ...profileData, username: showedUserName, isFakeUsername: !profileData.username });

      if (includeFavorites) await fetchFavorites();
      if (includeHolderPirates) await fetchHolderTokenIds(profileData);
      if (includeHolderPirates) await fetchFlowHolderTokenIds(profileData);

      if(localStorage.getItem('isNewAccount') === 'true'){
        localStorage.setItem('isNewAccount', 'false');
        router.push('/account');
      }

    } else {
      await authState.logout(true);
      await ethereumAccount.disconnect();
      toast.info("Something is wrong. Connect your wallet again please.");
      console.log('PROFILE RELOAD');
      window.location.reload();
    }

    if(setLoadingState){
      setLoading(false);
    }
  };
  // }, [authState, ethereumAccount, flowState, router, fetchFlowHolderTokenIds, fetchHolderTokenIds, fetchFavorites]);

  useEffect(()=>{
    // If the events propagation is disabled, we don't want to do anything
    if(disableEthPropagation.current) return;

    /**
     * 1. Session is not loading
     * 2. Session is empty (logged)
     * 3. Metamask isn't loading and doesn't have a default address
     */
    if (!authState.logged && !authState.loadingSession && (!ethereumAccount.connecting && !ethereumAccount.default)) 
      setLoading(false);

  }, [authState, ethereumAccount]);

  useEffect(()=>{
    // If the events propagation is disabled, we don't want to do anything
    if(disableEthPropagation.current) return;

    if (authState.logged && ethereumAccount.default) fetchAccount();    
  }, [authState.logged, ethereumAccount.default]);

  useEffect(()=>{
    // If the events propagation is disabled, we don't want to do anything
    if(disableEthPropagation.current) return;

    if(!ethereumAccount.default && !ethereumAccount.loading && !authState.loadingSession){
      const { resetContracts } = contracts;
      
      // Remove token locally - We don't want to remove all token for this account/user
      authState.logout(true);

      // Reset all profile data
      setProfile(undefined);
      setFavorites([]);
      setHolderPiratesIds([]);
      setPrimaryHolderPiratesIds([]);
      setHolderFlowPiratesIds([]);
      resetContracts();
    }
  }, [ethereumAccount.default, authState.loadingSession]);

  useEffect(()=>{
    // If the events propagation is disabled, we don't want to do anything
    if(disableEthPropagation.current) return;

    // TODO: after comment this, Check if affect something on disconnect
    // if(ethereumAccount.default) fetchHolderTokenIds(profile);

    if(flowState.user.addr) fetchFlowHolderTokenIds(profile);
  }, [contracts, profile, ethereumAccount.default]);
 

  const reFetch = (includeHolderPirates = false, includeFavorites = false, setLoadingState = false) => 
    fetchAccount(includeHolderPirates, includeFavorites, setLoadingState);

  const reFetchFavorite = () => fetchFavorites();

  const refreshHolderPirates = async () => {
    await fetchHolderTokenIds();
    await fetchFlowHolderTokenIds();
  }

  const contextValue: IProfileContext = {
    profile,
    ethereumAccount,
    reFetch,
    reFetchFavorite,
    refreshHolderPirates,
    loadingProfile,
    favorites,
    holderPiratesIds,
    primaryHolderPiratesIds,
    holderFlowPiratesIds,
    flowState,
    disableEthPropagation,
    ...authState,
  }

  return (
    <ProfileContext.Provider value={contextValue} >
      {children}
    </ProfileContext.Provider>
  );
};

ProfileProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ProfileProvider;
